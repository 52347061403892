import {
  Peripheral,
  Device_CommissioningState,
  Product,
  Product_Type,
  Device_Mode,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { Command } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/command_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { ListMetricsRequest_Target } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { idFromName } from '~/shared/models/id-utils';
import { IssueModel } from '~/shared/models/issues/IssueModel';
import { DeviceModel } from '~/shared/models/system/DeviceModel';
import { ReservoirModel } from '~/shared/models/system/ReservoirModel';
import { PeripheralModel } from '~/shared/models/system/PeripheralModel';
import { assertUnreachable } from '~/types/assert-type';

const SUPPORTED_METRICS_TYPES = [
  MetricType.AIR_TEMPERATURE_CELSIUS,
  MetricType.WATER_TEMPERATURE_CELSIUS,
  MetricType.WATER_HEIGHT_MM,
  MetricType.PRECIPITATION_MM,
  MetricType.VALVE_HEIGHT_MM,
  MetricType.BATTERY_PERCENTAGE,
  MetricType.WIND_SPEED_METER_PER_SECOND,
  MetricType.CELLULAR_RSSI_DBM,
  MetricType.TAP_ON,
] as const;

export type SupportedMetricType = (typeof SUPPORTED_METRICS_TYPES)[number];

export type MetricTypeOption = {
  resourceName: string;
  type: SupportedMetricType;
};

export function isSupportedMetricType(
  metricType: MetricType,
): metricType is SupportedMetricType {
  return SUPPORTED_METRICS_TYPES.includes(metricType as SupportedMetricType);
}

const createMetricTarget = (resourceName: string, types: MetricType[]) => {
  const filteredTypes = types.filter(isSupportedMetricType);
  return new ListMetricsRequest_Target({ resourceName, types: filteredTypes });
};

export type ProductIconType =
  | 'DropIcon'
  | 'LevelIcon'
  | 'TapIcon'
  | 'CloudRainIcon'
  | 'PumpIcon';

export type ProductState =
  | 'HAS_ISSUES'
  | 'READY_FOR_LINKING'
  | 'PENDING_CLOUD_CONNECTION'
  | 'READY_FOR_TESTING'
  | 'OPERATIONAL'
  | 'MAINTENANCE';

export type ControlTypes = 'valve' | 'tap';

function deriveProductState(
  commissioningState: Device_CommissioningState,
  deviceMode: Device_Mode,
  hasIssues: boolean,
): ProductState {
  switch (commissioningState) {
    case Device_CommissioningState.READY_FOR_LINKING:
      return 'READY_FOR_LINKING';
    case Device_CommissioningState.PENDING_CLOUD_CONNECTION:
      return 'PENDING_CLOUD_CONNECTION';
    case Device_CommissioningState.READY_FOR_TESTING:
      return 'READY_FOR_TESTING';
    case Device_CommissioningState.OPERATIONAL:
      if (deviceMode === Device_Mode.MAINTENANCE) {
        return 'MAINTENANCE';
      }
      if (hasIssues) {
        return 'HAS_ISSUES';
      }
      return 'OPERATIONAL';
    case Device_CommissioningState.UNSPECIFIED:
      throw new Error('Unspecified commissioning state');
    default:
      return assertUnreachable(commissioningState);
  }
}

export type ProductInfo = {
  peripherals: Peripheral[];
  product: Product;
  issues: IssueModel[];
  device: DeviceModel;
};

export class ProductModel {
  readonly id: string;

  readonly name: string;

  readonly type: Product_Type;

  readonly icon: ProductIconType;

  readonly title: string | undefined;

  readonly maxValveHeight: number;

  readonly reservoirTitle: string;

  readonly previousProductId: string;

  readonly nextProductId: string;

  readonly availableMetricTargets: ListMetricsRequest_Target[];

  readonly availableMetricChartOptions: MetricTypeOption[];

  readonly openValveCommand: Command | undefined;

  readonly closeValveCommand: Command | undefined;

  readonly openTapCommand: Command | undefined;

  readonly closeTapCommand: Command | undefined;

  readonly maintenanceModeCommand: Command | undefined;

  readonly setValveHeightCommand: Command | undefined;

  readonly reservoirPosition: 'top' | 'bottom' | 'side';

  readonly state: ProductState;

  readonly issues: IssueModel[];

  readonly device: DeviceModel;

  readonly allowChangingDevice: boolean;

  readonly showControlActions: boolean = false;

  readonly isSelectable: boolean;

  readonly controlType: ControlTypes | undefined;

  readonly peripherals: PeripheralModel[];

  readonly isConnectedToDrain: boolean;

  get hasValve(): boolean {
    return !!(this.openValveCommand || this.closeValveCommand);
  }

  get hasTap(): boolean {
    return !!(this.openTapCommand || this.closeTapCommand);
  }

  constructor({
    nextProductName,
    peripherals,
    previousProductName,
    product,
    reservoir,
    issues,
    device,
  }: ProductInfo & {
    nextProductName: string;
    previousProductName: string;
    reservoir: ReservoirModel;
  }) {
    this.id = idFromName(product.name);
    this.name = product.name;
    this.reservoirTitle = reservoir.title;
    this.previousProductId = idFromName(previousProductName);
    this.nextProductId = idFromName(nextProductName);
    this.title = product.title || undefined;
    this.type = product.type;
    this.isConnectedToDrain = !!product.position?.drainName;
    this.issues =
      device.commissioningState === Device_CommissioningState.OPERATIONAL
        ? issues
        : [];

    if (device.commissioningState === Device_CommissioningState.UNSPECIFIED) {
      throw new Error('Unsupported commissioning state: UNSPECIFIED');
    }

    this.device = device;

    const productState = deriveProductState(
      device.commissioningState,
      device.mode,
      !!issues.length,
    );

    this.state = productState;

    switch (product.type) {
      case Product_Type.SMART_DROP:
        this.icon = 'DropIcon';
        this.reservoirPosition = 'bottom';
        break;
      case Product_Type.SMART_TAP: {
        this.icon = 'TapIcon';
        this.reservoirPosition = 'top';
        break;
      }
      case Product_Type.SMART_RAIN_METER: {
        this.icon = 'CloudRainIcon';
        this.reservoirPosition = 'top';
        break;
      }
      case Product_Type.SMART_LEVEL: {
        this.icon = 'LevelIcon';
        this.reservoirPosition = 'top';
        break;
      }
      case Product_Type.PUMP: {
        this.icon = 'PumpIcon';
        this.reservoirPosition = 'side';
        break;
      }
      default:
        throw new Error(`Unexpected product type: ${product.constructor.name}`);
    }

    this.maxValveHeight = reservoir.maxWaterHeightMm;

    this.maintenanceModeCommand = device.maintenanceModeCommand;

    const availableMetricTargets = [
      ...peripherals.map((peripheral) =>
        createMetricTarget(peripheral.name, peripheral.availableMetricTypes),
      ),
      createMetricTarget(device.name, device.availableMetricTypes),
    ];

    this.availableMetricTargets = availableMetricTargets.filter(
      ({ types }) => types.length > 0,
    );

    this.availableMetricChartOptions = this.availableMetricTargets.flatMap(
      ({ types, resourceName }) =>
        types.map((type) => ({
          resourceName,
          type: type as SupportedMetricType,
        })),
    );

    const peripheralModels = peripherals.map(
      (peripheral) => new PeripheralModel(peripheral),
    );

    this.peripherals = peripheralModels;

    this.openValveCommand = peripheralModels.find(
      (peripheral) => peripheral.openValveCommand,
    )?.openValveCommand;
    this.closeValveCommand = peripheralModels.find(
      (peripheral) => peripheral.closeValveCommand,
    )?.closeValveCommand;
    this.openTapCommand = peripheralModels.find(
      (peripheral) => peripheral.openTapCommand,
    )?.openTapCommand;
    this.closeTapCommand = peripheralModels.find(
      (peripheral) => peripheral.closeTapCommand,
    )?.closeTapCommand;
    this.setValveHeightCommand = peripheralModels.find(
      (peripheral) => peripheral.setValveHeightCommand,
    )?.setValveHeightCommand;

    this.allowChangingDevice =
      product.type === Product_Type.SMART_TAP ||
      product.type === Product_Type.SMART_RAIN_METER;

    const canShowControlActions = () => {
      if (!this.hasTap && !this.hasValve) {
        return false;
      }

      if (
        this.state === 'READY_FOR_LINKING' ||
        this.state === 'PENDING_CLOUD_CONNECTION' ||
        this.state === 'READY_FOR_TESTING'
      ) {
        return false;
      }

      return true;
    };

    this.showControlActions = canShowControlActions();

    const resolveControlType = () => {
      if (this.hasTap) {
        return 'tap';
      }
      if (this.hasValve) {
        return 'valve';
      }
      return undefined;
    };

    this.controlType = resolveControlType();

    const canBeSelected = () => {
      const hasDevice =
        product.type === Product_Type.SMART_DROP ||
        product.type === Product_Type.SMART_LEVEL;

      if (hasDevice) {
        return true;
      }

      if (device.commissioningState === Device_CommissioningState.OPERATIONAL) {
        return true;
      }

      return false;
    };

    this.isSelectable = canBeSelected();
  }

  getDeviceOptions(devices: DeviceModel[]) {
    const filterDevices = () => {
      switch (this.type) {
        case Product_Type.SMART_TAP:
          return devices.filter((device) => device.allowLinkingTap);
        case Product_Type.SMART_RAIN_METER:
          return devices.filter((device) => device.allowLinkingRainSensor);
        default:
          throw new Error(`Unexpected product type: ${this.type}`);
      }
    };

    const filteredDevices = filterDevices();

    filteredDevices.push(this.device);

    const emptyDevice = DeviceModel.createEmpty();

    const hasEmptyOption = filteredDevices.find(
      (device) => device.name === emptyDevice.name,
    );

    if (!hasEmptyOption) {
      filteredDevices.push(emptyDevice);
    }

    return filteredDevices.map((device) => ({
      name: device.name,
      label: device.title || device.name,
      disabled: device.name === this.device.name,
    }));
  }
}
